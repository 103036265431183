import * as Inflector from 'inflector-js'

const titleCaseOverrides = {
  'Magic: the Gathering': 'Magic: The Gathering',
  'D&d': 'D&D',
  'D&d Other': 'D&D Other',
  'Magic Fest': 'MagicFest'
}
export default {
  init
}

function init (Vue) {
  Vue.filter('toLowerCase', function (value) {
    if (value && value.toLowerCase) {
      return value.toLowerCase()
    }

    return value
  })

  Vue.filter('toTitleCase', function (value) {
    const titleCaseValue = Inflector.camel2words(value)
    const overrideValue = titleCaseOverrides[titleCaseValue]
    if (overrideValue) {
      return overrideValue
    }
    return titleCaseValue
  })
}

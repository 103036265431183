<template>
  <footer>
    <connect-on-social-media />
    <company-links />
    <terms />
    <copyright />
  </footer>
</template>

<script>
import CompanyLinks from './CompanyLinks.vue'
import ConnectOnSocialMedia from './ConnectOnSocialMedia.vue'
import Copyright from './Copyright.vue'
import Terms from './Terms.vue'

export default {
  name: 'PageFooter',
  components: {
    ConnectOnSocialMedia,
    CompanyLinks,
    Terms,
    Copyright
  },
  props: [],
  methods: {}
}
</script>

<style lang="scss">
footer {
  background-color: $footer-bg-color;
  z-index: -1;
}
</style>

<template>
  <div
    class="sel-navbar hidenav"
    :class="{'header-border': isHome }"
  >
    <div class="sel-navbar__container">
      <div class="sel-navbar-logo__container">
        <a
          href="https://company.wizards.com"
          title="wizards of the coast"
        >
          <img
            class="sel-navbar-logo"
            :src="require('@/assets/logo-wotc-2023.svg')"
            alt="wizards of the coast"
          >
        </a>
      </div>
      <div class="search-page-header">
        <router-link
          v-if="showHeaderTitle"
          tag="a"
          class="search-page-title"
          to="/"
        >
          {{ $root.$t('header__title') }}
        </router-link>
      </div>
      <div class="sel-navbar-controls sel-navbar__section">
        <a href="mailto:feedback@wizards.com?subject=Wizards Event Locator Feedback">
          <i
            class="fa fa-comment"
            aria-hidden="true"
          /> {{ $root.$t('header__feedback') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isHome () {
      return this.$route.name === 'home'
    },
    showHeaderTitle () {
      return this.$route.name === 'search'
    }
  }
}
</script>

<style lang="scss">
.sel-navbar {
  padding: 0.5rem 0 0.5rem 0;
  background-color: #0A0014;
  color: map-get($theme-colors, white-color);
}

.header-border {
  border-bottom: 4px solid map-get($theme-colors, 'bold-gray');
}

.sel-navbar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 28px 0 22px;
  margin: 0 auto;
}

.sel-navbar-controls {
  text-align: right;
  a{
    color: #0091FF;
    &:hover {
      color: #0091FF;
    }
  }
}

.search-page-header {
  a {
    font-family: $font-opensans;
    font-weight: $fontweight-semibold;
    font-size: 30px;
    text-transform: uppercase;
    color: map-get($theme-colors, white-color);
    &:hover {
      color: map-get($theme-colors, white-color);
      text-decoration: none;
    }
  }
}

.sel-navbar-logo {
  width: 80px;
}

.sel-navbar-controls {
  text-align: right;
  a{
    color: #0091FF;
  }
}

@include media-breakpoint-down(md) {
  .hidenav{
    display: none;
  }
}

@include media-breakpoint-down(xs) {
  .sel-navbar {
    font-size: 14px;
  }

  .sel-navbar-controls {
    padding-left: 0;
  }

  .sel-navbar-logo {
    width: 60px;
  }
}
</style>

import { gql } from '@/gql'
import { EventSearchQuery, EventSearchSortDirection, EventSearchSortField, InputMaybe, Query, StoreByLocationInput } from '@/gql/graphql'
import { ApolloQueryResult } from 'apollo-client'
import { DocumentNode } from 'graphql'
import { apolloClient } from './apollo'

interface IQueryLatLngStore {
    latitude: number,
    longitude: number,
    maxMeters: number,
    isPremium?: boolean | null,
    pageSize?:number,
    page?:number

}

interface IQueryEvents {
    latitude: number,
    longitude: number,
    maxMeters: number,
    tags?: InputMaybe<string[]>,
    startDate?: Date,
    endDate?: Date,
    orgs?: InputMaybe<string[]>,
    page?: number,
    pageSize?:number,
    sort? :InputMaybe<EventSearchSortField>,
    sortDirection? : InputMaybe<EventSearchSortDirection>,
}

export const queryStoresByLatLng = ({ latitude, longitude, maxMeters, isPremium = null, pageSize = 1000, page = 0 }: IQueryLatLngStore): Promise<ApolloQueryResult<Query>> => {
  const query = gql(`
    query getStoresByLocation(
        $latitude: Float!
        $longitude: Float!
        $maxMeters: Int!
        $pageSize: pageSize_Int_min_1
        $page: page_Int_min_0
        $isPremium: Boolean){
            storesByLocation(
                input:{ 
                    latitude: $latitude, 
                    longitude: $longitude, 
                    maxMeters: $maxMeters, 
                    pageSize: $pageSize,
                    page: $page,
                    isPremium: $isPremium
                }){
                stores {
                    emailAddress
                    id
                    isPremium
                    latitude
                    longitude
                    name
                    postalAddress
                    showEmailInSEL
                    showInInternalSearch
                   distance
                   phoneNumber
                   website
                }
                pageInfo{
                    page
                    pageSize
                    totalResults
                }
                
            }
}
  `) as DocumentNode

  const variables: StoreByLocationInput = {
    latitude,
    longitude,
    maxMeters,
    isPremium,
    pageSize,
    page
  }
  return apolloClient.query(
    {
      query,
      variables
    }
  )
}

export const queryStoresById = (id: string): Promise<ApolloQueryResult<Query>> => {
  const query = gql(`
    query getStoresByIds(
        $id: [ID!]!
        ){
            storesByIds(ids: $id){
                    emailAddress
                    id
                    isPremium
                    latitude
                    longitude
                    name
                    postalAddress
                    showEmailInSEL
                    showInInternalSearch
                   distance
                   phoneNumber
                   website
               
                
            }
}
  `) as DocumentNode

  const variables = {
    id
  }
  return apolloClient.query(
    {
      query,
      variables
    }
  )
}

export const queryEvents = ({
  latitude,
  longitude,
  maxMeters,
  tags = [],
  startDate,
  endDate,
  orgs,
  page = 0,
  pageSize = 1000,
  sort,
  sortDirection
}: IQueryEvents
): Promise<ApolloQueryResult<Query>> => {
  const query = gql(`
    query queryEvents(
        $latitude: Float!
        $longitude: Float!
        $maxMeters: maxMeters_Int_NotNull_min_1!
        $tags: [String!]!
        $sort: EventSearchSortField
        $sortDirection: EventSearchSortDirection
        $orgs: [ID!]
        $startDate: DateTime
        $endDate: DateTime
        $page: page_Int_min_0
        $pageSize: pageSize_Int_min_1
        ){
            searchEvents(
                query:{ 
                    latitude: $latitude
                    longitude: $longitude 
                    maxMeters: $maxMeters 
                    tags: $tags
                    sort: $sort 
                    sortDirection: $sortDirection 
                    orgs: $orgs
                    startDate: $startDate 
                    endDate: $endDate
                    page: $page 
                    pageSize: $pageSize 
                }){
                events {
                id
                    capacity
                    description
                    distance
                    emailAddress
                    hasTop8
                    isAdHoc
                    isOnline
                    latitude
                    longitude
                    title
                    eventTemplateId
                    pairingType
                    phoneNumber
                    requiredTeamSize
                    rulesEnforcementLevel
                    scheduledStartTime
                    startingTableNumber
                    status
                    tags
                    timeZone
                    cardSet {
                    id
                    }
                    entryFee {
                        amount
                        currency
                    }
                
                    organization {
                        id
                        isPremium
                        name
                    }
                    eventFormat {
                        id
                    }
                }
                pageInfo{
                    page
                    pageSize
                    totalResults
                }
            }
}
  `) as DocumentNode

  const variables: EventSearchQuery = {
    latitude,
    longitude,
    maxMeters,
    tags,
    sort,
    sortDirection,
    startDate,
    endDate,
    orgs,
    pageSize,
    page
  }
  return apolloClient.query(
    {
      query,
      variables
    }
  )
}

export const queryEventById = (id: string):Promise<ApolloQueryResult<Query>> => {
  const variables: {id: string} = {
    id
  }

  const query = gql(`
    query queryEventById($id: ID!){
            event(id: $id){
                    id
                    capacity
                    description
                    distance
                    emailAddress
                    hasTop8
                    isAdHoc
                    isOnline
                    latitude
                    longitude
                    title
                    eventTemplateId
                    pairingType
                    phoneNumber
                    requiredTeamSize
                    rulesEnforcementLevel
                    scheduledStartTime
                    startingTableNumber
                    status
                    tags
                    timeZone
                    cardSet {
                    id
                    }
                    entryFee {
                        amount
                        currency
                    }
                
                    organization {
                        id
                        isPremium
                        name
                        phoneNumber
                        website
                    }
                    eventFormat {
                        id
                    }
                }
            }

  `) as DocumentNode

  return apolloClient.query(
    {
      query,
      variables
    }
  )
}



export const queryBrands = ():Promise<ApolloQueryResult<Query>> => {


  const query = gql(`
    query brands{
      brands {
        brandName,
        brandTag {
          tagName,
          tag,
          isActive
        }
        isActive
      }
    }

  `) as DocumentNode

  return apolloClient.query(
    {
      query    }
  )
}

export const queryEventTypes = ():Promise<ApolloQueryResult<Query>> => {


  const query = gql(`
    query eventTypes{
      eventTypes {
        typeName,
        isActive,
        typeTag {
          tagName,
          tag,
          isActive
        }
        supportedBrands{
          brandName
        }
      }
    }

  `) as DocumentNode

  return apolloClient.query(
    {
      query    }
  )
}

export const queryEventFormats = ():Promise<ApolloQueryResult<Query>> => {


  const query = gql(`
    query eventFormats{
      eventFormats {
        name
        isActive
        supportedBrands {
          brandName
          brandTag {
            tag
          tagName
          isActive
          }
        }
        eventTag {
          tag
          tagName
          isActive
        }
        
      }
    }

  `) as DocumentNode

  return apolloClient.query(
    {
      query    }
  )
}


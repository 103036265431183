import { EventFormat, EventType, StoreBrand } from './gql/graphql.js';
import { queryBrands, queryEventFormats, queryEventTypes } from './misc/queries.js';

const eventFormats: EventFormat[] = (await queryEventFormats()).data.eventFormats.filter((format: EventFormat) => format.isActive);
const eventTypes: EventType[] = (await queryEventTypes()).data.eventTypes.filter((event: EventType) => event.isActive);
const brands: StoreBrand[]= (await queryBrands()).data.brands.filter((brand: StoreBrand) => brand.brandTag.tag === "magic:_the_gathering" || brand.brandTag.tag === "dungeons_&_dragons");

const mtgBrand: StoreBrand = brands.filter((brand: StoreBrand) => brand.brandTag.tag === "magic:_the_gathering")[0];
const mtgFormats:EventFormat[]  = eventFormats.filter((event: EventFormat) => {
  const match = event.supportedBrands?.filter((brand: StoreBrand) => brand.brandTag.tag === mtgBrand.brandTag.tag)[0]
  if(match) {
    return event
  } else {
    return null;
  }
});
export const toFilter = (t:string) => ({ name: t, key: 'tag', value: t, hasItems: true })

export default {
  STORE_FILTERS: [
    {
      name: 'Stores',
      filters: [
        { name: 'Premium', key: 'tag', value: 'premium', hasItems: true }
      ]
    }
  ],
  DND_FILTERS: [
    {
      name: 'Event Tags',
      filters: [
        {
          name: 'Dungeons & Dragons',
          key: 'tag',
          value: 'dungeons_&_dragons',
          hasItems: true
        }
      ]
    },
    {
      name: 'Store',
      filters: [
        { name: 'Premium', key: 'tag', value: 'premium', hasItems: true }
      ]
    }
  ],
  EVENT_FILTERS: [
    {
      name: 'Event Types',
      filters: eventTypes.map((event: EventType) => toFilter(event.typeTag.tag))
    },
    {
      name: 'Formats',
      filters: mtgFormats.map((format: EventFormat) => toFilter(format.eventTag.tag))
    },
    {
      name: 'Store',
      filters: [
        { name: 'Premium', key: 'tag', value: 'premium', hasItems: true }
      ]
    }
  ],
  MEASUREMENT_SYSTEM_METRIC: 1,
  MEASUREMENT_SYSTEM_IMPERIAL: 2,
  SEARCH_TYPE_MAGIC_EVENTS: 'magic-events',
  SEARCH_TYPE_STORES: 'stores',
  SEARCH_TYPE_MAGIC_PREMIER_EVENTS: 'magic-premier-events',
  SEARCH_TYPE_DND: 'dnd',
  SEARCH_TAGS: () => {
    
  //TODO: When division between Hasbro and WOTC products will be available in API change that hardcoded part to be parameter reliant.
  const map: {[x:string]: string} = {};
  map[window.CONSTANTS.SEARCH_TYPE_MAGIC_EVENTS] = 'magic:_the_gathering'
  map[window.CONSTANTS.SEARCH_TYPE_MAGIC_PREMIER_EVENTS] = 'magic_premier_event'
  map[window.CONSTANTS.SEARCH_TYPE_DND] = "dungeons_&_dragons"
  return map
},
  DEFAULT_SEARCH_LOCATION: {
    formatted_address: 'Los Angeles, CA, USA',
    geometry: {
      location: {
        lat: function () {
          return 34.0522342
        },
        lng: function () {
          return -118.2436849
        }
      },
      viewport: {
        south: 33.7036519,
        west: -118.6681759,
        north: 34.3373061,
        east: -118.1552891
      }
    },
    name: 'Los Angeles'
  },
  TAG_HINTS: {
    'two-headed_giant': {
      key: 'tag_text__two_headed_giant',
      url:
        'https://magic.wizards.com/en/game-info/gameplay/formats/two-headed-giant'
    },
    booster_draft: {
      key: 'tag_text__booster_draft',
      url:
        'https://magic.wizards.com/en/game-info/gameplay/formats/booster-draft'
    },
    commander: {
      key: 'tag_text__commander',
      url: 'https://magic.wizards.com/en/content/commander-format'
    },
    legacy: {
      key: 'tag_text__legacy',
      url: 'https://magic.wizards.com/en/game-info/gameplay/formats/legacy'
    },
    modern: {
      key: 'tag_text__modern',
      url: 'https://magic.wizards.com/en/game-info/gameplay/formats/modern'
    },
    multiplayer: {
      key: 'tag_text__multiplayer'
    },
    sealed_deck: {
      key: 'tag_text__sealed_deck',
      url: 'https://magic.wizards.com/en/game-info/gameplay/formats/sealed-deck'
    },
    standard: {
      key: 'tag_text__standard',
      url:
        'https://magic.wizards.com/en/content/standard-formats-magic-gathering'
    }
  }
}

import VueGtm from '@gtm-support/vue2-gtm'
import GmapVue from 'gmap-vue'
import Vue from 'vue'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'
import VueI18n from 'vue-i18n'
import Meta from 'vue-meta'
import VueMoment from 'vue-moment'

import wotcAnalytics from '@/features/common/Analytics.interface.js'
import { dateTimeFormats, findBestLocale, messages } from '@/lang/config.js'
import apolloProvider from '@/misc/apollo.ts'
import App from './App.vue'
import ComponentInit from './features/common/components/Component.init'
import ToLowerCaseFilter from './filters/lowerCase.filter'
import MetersToStandardUnitsFilter from './filters/metersToStandardUnits.filter'
import router from './router'

import APP_SETTINGS from './appsettings'
import CONSTANTS from './constants'

import '@/scss/app.scss'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

window.APP_SETTINGS = APP_SETTINGS
window.CONSTANTS = CONSTANTS

wotcAnalytics.init({
  siteURL: import.meta.url,
  querySize: 1,
  serviceUrl: APP_SETTINGS.WOTC_ANALYTICS_URL
})

Vue.use(Meta)

// set up internationalization
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: findBestLocale(navigator.languages),
  fallbackLocale: 'en',
  messages,
  dateTimeFormats
})

// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, {
  sundayFirst: true,
  colors: {
    selected: '#33dacd',
    inRange: '#66e2da',
    selectedText: '#000',
    text: '#565a5c',
    inRangeBorder: '#33dacd',
    disabled: '#fff',
    hoveredInRange: '#67f6ee'
  }
})

// set up google analytics
Vue.use(VueAnalytics, {
  id: window.APP_SETTINGS.GOOGLE_ANALYTICS_ID,
  router
})

Vue.use(VueGtag, {
  config: {
    id: window.APP_SETTINGS.GOOGLE_TAG_MANAGER_ID
  }
}, router)

Vue.use(VueGtm, {
  id: window.APP_SETTINGS.GOOGLE_TAG_MANAGER_ID,
  vueRouter: router
})

// set up google maps
Vue.use(GmapVue, {
  dynamicLoad: true
})

MetersToStandardUnitsFilter.init(Vue)
ToLowerCaseFilter.init(Vue)
ComponentInit.init(Vue)
Vue.use(VueMoment)
// stop annoying you're in a dev enviroment message
Vue.config.productionTip = false

window.$vue = new Vue({
  i18n,
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import it from './it'
import ja from './ja'
import ko from './ko'
import pt from './pt'
import ru from './ru'
import zh_CN from './zh-CN'
import zh_TW from './zh-TW'

export const messages = {
  ...de,
  ...en,
  ...es,
  ...fr,
  ...it,
  ...ja,
  ...ko,
  ...pt,
  ...ru,
  ...zh_CN,
  ...zh_TW
}

export function findBestLocale (preferredLocales) {
  const availableLocales = Object.keys(messages)

  const normalizedPreferredLocales = preferredLocales.map(locale => {
    if (locale.startsWith('zh-')) return locale
    return locale.split('-')[0]
  })

  const found = normalizedPreferredLocales.find(pref => availableLocales.includes(pref))

  return found
}

export const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    weekday: { weekday: 'long' },
    month: { month: 'long' },
    day: { day: 'numeric' }
  },
  'ja-JP': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    },
    weekday: { weekday: 'long' },
    month: { month: 'long' },
    day: { day: 'numeric' }
  }
}

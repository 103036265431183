import uuidv4 from 'uuid/v4'

// ------------------------------
// # Wizards AnalyticsReporter. HTTP subsystem for reporting user events to wizards BI injest.
//
// The AnalyticsReporter module exports functions to directly report user interaction events.
// See the "API FUNCTIONS" section.
//
// ```
// import report from 'AnalyticsReporter.js'
// report.reportInteraction('USER-CLICKED-THE-BUTTON', {buttonMsg: 'hello'})
// ```
// ------------------------------

// Defines the behavior of the analytics reporting.
// Defaults can be overriden by an object passed to the init function.
const analyticsConfig = {
  siteURL: '',
  queueSize: 4,
  serviceUrl: undefined
}

// SessionData created by init on call.
const sessionData = {
  session: '', // a UUID representing the current session
  sessionStart: '', // ISO datetime string for the session start
  language: '', // browser language
  user: '', // user ID for cross app wizards analytics
  url: '', // URL for current app,
  ip: 'UNKNOWN' // IP for client
}

// A queue of message objects to be sent when a flush is called for.
let messageQueue = []

// API FUNCTIONS

// Initializes the AnalyticsReporter functionality with the given config.
// Supported config options:
// siteURL -- the URL of the current site
// querySize -- the number of messages to store before sending to the server
// serviceUrl -- the URL for the service that receives the analytics payload
const init = async (config) => {
  if (config) {
    Object.assign(analyticsConfig, config)
  }
  sessionData.session = uuidv4()

  sessionData.sessionStart = (new Date()).toISOString()
  // record the browser language
  sessionData.language = window.navigator.userLanguage || window.navigator.language || 'UNKNOWN'
  // check for the existence of a user id
  let userId = localStorage.getItem('userId')
  if (!userId) {
    userId = uuidv4()
    localStorage.setItem('userId', userId)
  }
  sessionData.user = userId
  // record the site URL
  sessionData.url = config.siteURL
  // try to capture the user's IP
  try {
    const response = await fetch('https://api.ipify.org?format=json')
    const ipObj = await response.json()
    sessionData.ip = ipObj.ip
  } catch (error) {
    console.error(error)
  }
  // enqueue the session start event
  reportInteraction('analyticsStart', { sessionStart: sessionData.sessionStart })
}

// Sends all enqueued analytics event objects to the server and clears the queue.
const flush = () => {
  send()
  messageQueue = []
}

// timestamp -- REPORTER
// sessionUniqueIdentifier -- REPORTER
// ipAddress -- REPORTER (add) https://api.ipify.org/?format=json
// language -- REPORTER

// Adds an interaction to the analytics event queue.
// Causes an automatic flush of the queue if the number of enqueued analytics events
// is the same as the queueSize config.
const reportInteraction = (interactionKey, detailsObj = {}) => {
  const interaction = Object.assign(
    {},
    sessionData,
    detailsObj,
    { type: interactionKey, timestamp: (new Date()).toISOString() }
  )
  messageQueue.push(interaction)
  if (messageQueue.length >= analyticsConfig.queueSize) {
    if (analyticsConfig.serviceUrl) {
      flush()
    }
  }
}

// HELPER FUNCTIONS

const send = () => {
  if (messageQueue.size === 0) { return }
  // This is a non-interrupting POST of data.
  // We don't listen for a response and we don't trigger
  // user facing alerts on failure.
  fetch(analyticsConfig.serviceUrl, {method: "POST", body: JSON.stringify(messageQueue)})
}

// MODE EXPORT

export default {
  init,
  flush,
  reportInteraction
}

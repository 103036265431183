<template>
  <div class="social">
    <div class="social__icons">
      <a
        class="social__icon"
        href="https://www.facebook.com/Wizards-of-the-Coast-540208589333239/"
        target="_blank"
        :style="getSocialIcon('facebook')"
      />
      <a
        class="social__icon"
        href="https://twitter.com/wizards"
        target="_blank"
        :style="getSocialIcon('twitter')"
      />
      <a
        class="social__icon"
        href="https://www.linkedin.com/company/wizards-of-the-coast"
        target="_blank"
        :style="getSocialIcon('linkedin')"
      />
    </div>
  </div>
</template>

<script>
import EventContentHelper from '@/features/common/EventContent.helper.js'
export default {
  name: 'ConnectOnSocialMedia',
  methods: {
    getSocialIcon (name) {
      if (!name) return
      const svg = EventContentHelper.getSocialIconByName(name)
      return {
        backgroundImage: `url("${svg}")`
      }
    }
  }
}
</script>

<style lang="scss">
  .social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: $footer-social-bg-color;
    width: 100%;
    height: 63px;
    border-bottom: 0.5px solid map-get($theme-colors, footer-border);
    padding-left: 176px;
  }
  .social__copy {
    color: $footer-social-fg-color;
    font-size: 14px;
    font-weight: $fontweight-semibold;
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
  .social__icons {
    display: flex;
    a {
      width: 42px;
      height: 42px;
      margin-left: 13px;
    }
  }
  @include media-breakpoint-down(sm) {
    .social {
      padding-left: 0;
      justify-content: center;
    }

    .social__copy {
      &:first-child { margin-bottom: 10px; }
      &:last-child { margin-top: 10px; }
    }
  }
</style>

export default {
  hasValue,
  getSingleLineAddressFromOrg,
  sharingMetaTags,
  formatPhoneNumber,
  getGoogleMapsZoomLevelBasedOnSearchDistance,
  getMeasurementSystem,
  getMeasurementSystemName
}

let zoomLevels

function hasValue (value) {
  if (value && value.trim) {
    return value.trim() !== ''
  }
  return value !== -1
}

function getSingleLineAddressFromOrg ({ name, postalAddress }) {
  // ICS wants a single line of text for a location
  return [name, postalAddress].join(' ').replace(/[\r\n]+/g, ' ')
}

function sharingMetaTags (title, description, imagePath, pagePath) {
  const canonicalUrl = new URL(pagePath, import.meta.url).href

  return [
    // OpenGraph
    {
      property: 'og:title',
      content: title
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      property: 'og:url',
      content: canonicalUrl
    },
    {
      property: 'og:image',
      content: imagePath
    },
    {
      property: 'og:description',
      content: description
    },

    // Twitter card
    {
      property: 'twitter:card',
      content: 'summary'
    },
    {
      property: 'twitter:site',
      content: canonicalUrl
    },
    {
      property: 'twitter:title',
      content: title
    },
    {
      property: 'twitter:description',
      content: description
    },
    {
      property: 'twitter:creator',
      content: window.APP_SETTINGS.TWITTER_AT
    },
    {
      property: 'twitter:img:src',
      content: imagePath
    },

    // Schema.org
    {
      property: 'name',
      content: title
    },
    {
      property: 'description',
      content: description
    },
    {
      property: 'image',
      content: imagePath
    }
  ]
}

function formatPhoneNumber (phoneNumberString) {
  let number = phoneNumberString.replace(/[^\d]/g, '')
  if (number.length === 10) {
    if (String(phoneNumberString).charAt(0) === '+') {
      if (String(phoneNumberString).charAt(1) === '1') {
        number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      } else {
        number = phoneNumberString
      }
    } else {
      number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    }
  } else {
    number = phoneNumberString
  }
  return number
}

function getGoogleMapsZoomLevelBasedOnSearchDistance (distance) {
  if (!zoomLevels) {
    zoomLevels = {}
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL + '-' + 40075] = 1
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL + '-' + 500] = 5
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL + '-' + 100] = 7
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL + '-' + 50] = 8
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL + '-' + 25] = 9
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL] = 10

    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC + '-' + 40075] = 1
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC + '-' + 500] = 6
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC + '-' + 100] = 9
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC + '-' + 50] = 9
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC + '-' + 25] = 10
    zoomLevels[window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC] = 12
  }
  const key = getMeasurementSystem() + '-' + distance
  if (zoomLevels[key]) {
    return zoomLevels[key]
  }
  return zoomLevels[getMeasurementSystem()]
}

function getMeasurementSystem () {
  if (navigator.language === 'en-US') {
    return window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL
  }
  return window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC
}

function getMeasurementSystemName () {
  return getMeasurementSystem() === window.CONSTANTS.MEASUREMENT_SYSTEM_IMPERIAL ? 'miles' : 'kilometers'
}

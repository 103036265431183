import Vue from 'vue'
import Router from 'vue-router'
const HomePage = () => import('./features/HomePage')
const SearchPage = () => import('./features/SearchPage')
const EventDetailsPage = () => import('./features/EventDetailsPage')
const StoreDetailsPage = () => import('./features/StoreDetailsPage')
const NotFoundPage = () => import('./features/NotFoundPage')
const HealthPage = () => import('./features/HealthPage')
const CookiesPage = () => import('./features/CookiesPage')

Vue.use(Router)
const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      props: true,
      component: HomePage,
      alias: '/event-reservations-web'
    },
    {
      path: '/health',
      name: 'health',
      component: HealthPage
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: CookiesPage,
      props: true,
      meta: {
        skipAuth: true,
        skipTerms: true
      }
    },
    {
      path: '/search/:query?',
      alias: '/event-reservations-web/search/:query?',
      name: 'search',
      props: (route) => {
        if (!route.params.query) {
          return {}
        }
        const queryParts = route.params.query.split('-')
        const city = queryParts[2].split('.')[0]
        return {
          query: queryParts[1] + ', ' + city + ', ' + queryParts[0],
          city,
          state: queryParts[1]
        }
      },
      component: SearchPage
    },
    {
      path: '/events/:eventId/:autoReg?',
      alias: '/event-reservations-web/events/:eventId/:autoReg?',
      name: 'eventDetails',
      props: true,
      component: EventDetailsPage
    },
    {
      path: '/store/:orgId',
      alias: '/event-reservations-web/store/:orgId',
      name: 'storeDetails',
      props: true,
      component: StoreDetailsPage
    },
    {
      path: '/404',
      name: 'notFound',
      props: true,
      component: NotFoundPage
    },
    {
      path: '/mobile/jqmobile/*',
      redirect: '/'
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router

export default {
  "fr": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "Suivant",
    "pagination__previous": "Précédent",
    "sort_selector__date": "Date",
    "sort_selector__distance": "Distance",
    "event_details": "Détails de l'événement",
    "event_details__add_to_calendar": "Ajouter au calendrier",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "Effacer",
    "filters__clear_all_filters": "Effacer tous les filtres",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "Recherche d'événements | Recherche de magasin et d'événement de Wizards",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "Commentaires",
    "header__find_events": "Trouver des événements",
    "header__title": "Recherche de magasin et d'événement",
    "header__classic_link": "Locator Classic",
    "header__search__near": "près de :",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "distance",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "mi",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "Marques",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "À propos",
    "footer__company__careers": "Emplois",
    "footer__company__heading": "Entreprise",
    "footer__company__support": "Assistance",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, une filiale de Hasbro, Inc. Tous droits réservés.",
    "footer__find__articles": "Articles",
    "footer__find__club_support": "Club Support",
    "footer__find__convention_support": "Convention Support",
    "footer__find__digital_books": "Livres numériques",
    "footer__find__formats": "Formats",
    "footer__find__forums": "Forums",
    "footer__find__heading": "Accès rapide",
    "footer__find__military_support": "Military Support",
    "footer__find__rules": "Règles",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "Communautés",
    "footer__utils__code_of_conduct": "Code de conduite",
    "footer__utils__cookies": "Cookies",
    "footer__utils__customer_support": "Service client",
    "footer__utils__fan_content_policy": "Politique des contenus de fans",
    "footer__utils__privacy_policy": "Politique de confidentialité",
    "footer__utils__terms": "Conditions générales",
    "footer__utils__donotsell": "Je refuse que mes données personnelles soient vendues ou partagées",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>Envie de faire une partie où tout le monde est sur un même pied d'égalité et où vous pouvez découvrir de nouvelles cartes en même temps ? Alors un format Limité comme le Booster Draft est peut-être fait pour vous. Contrairement aux formats Construits où vous arrivez avec un deck soigneusement construit et prêt à jouer, dans les formats Limités, la construction d'un deck à partir de nouvelles cartes fait partie intégrante du jeu.</p><p>Au début du Booster Draft, chaque joueur ouvre un booster et choisit une seule carte. (Ne montrez pas ce que vous choisissez aux autres joueurs !) Tout le monde passe ensuite le reste de son booster au joueur se trouvant sur sa gauche ; chaque joueur choisit alors une carte du booster qu'il vient de recevoir avant de le faire passer à nouveau. L'opération continue jusqu'à ce que toutes les cartes de ces boosters aient été choisies. Ensuite, chaque joueur ouvre un nouveau booster, mais cette fois, vous devez passer les cartes au joueur situé à votre droite. Une fois que toutes ces cartes ont été choisies, vous faites la même chose avec le troisième booster, en passant à nouveau les cartes au joueur situé à gauche.</p>",
    "event_content__description__dnd": "<p>Le jeu de rôle Dungeons & Dragons consiste à narrer des aventures dans des mondes peuplés de guerriers et de magiciens. Il a certains points en commun avec ces jeux d'enfants où l'on s'amuse à faire semblant. Tout comme ces derniers, D&D repose sur l'imagination. Il faut se représenter un château se dressant par une sombre nuit de tempête et imaginer comment un aventurier inventé de toutes pièces réagirait aux dangers présents dans un tel endroit.</p><p>Contrairement aux jeux où l'on s'amuse à faire semblant, D&D apporte une structure aux histoires ainsi qu'un moyen de déterminer les conséquences des actions des aventuriers. Les joueurs lancent des dés pour savoir si leurs aventuriers réussiront à porter un coup, à escalader une falaise, à esquiver un éclair magique ou à entreprendre toute autre action risquée. Tout est possible, mais les dés rendent certains résultats plus probables que d'autres.</p><p>Dans Dungeons & Dragons, chaque joueur crée un aventurier (aussi appelé personnage) et fait équipe avec d'autres aventuriers (joués par des amis). En collaborant, le groupe pourra explorer un sombre donjon, une cité en ruines, un château hanté, un temple enfoui au plus profond d'une jungle ou une caverne remplie de lave située sous une mystérieuse montagne. Les aventuriers peuvent résoudre des énigmes, parler à d'autres personnages, affronter des monstres fantastiques et découvrir de fabuleux objets magiques ainsi que bien d'autres trésors.</p>",
    "event_content__description__magic": "Que vous n'ayez jamais joué à un jeu de cartes à collectionner ou que votre placard soit rempli de jeux de cartes, Magic: The Gathering a forcément quelque chose à vous offrir. Jouez en ligne ou à la version papier pour faire l'expérience palpitante d'un duel de sorciers. Explorez 30 ans d'histoires, de personnages et d'illustrations. Faites-vous de nouveaux amis lors des événements Magic organisés dans votre magasin de jeux local. Il existe de nombreuses façons de faire partie de la communauté Magic, et nous sommes heureux de vous compter parmi nous !",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "Format :",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft"
  }
}
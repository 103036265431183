import Utilities from '@/features/common/Utilities.helper'
export default {
  init
}
function init (Vue) {
  Vue.filter('toStandardUnits', function (value) {
    if (Utilities.getMeasurementSystem() === window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC) {
      return toKilometers(value) + getUnitString()
    }
    return toMiles(value) + getUnitString()
  })

  Vue.filter('noConversionValueInStandardUnits', function (value) {
    return value + getUnitString()
  })

  Vue.filter('toMetersFromStandardUnits', function (value) {
    if (Utilities.getMeasurementSystem() === window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC) {
      return value * 1000
    }
    return value * 1609.34
  })
}

function getUnitString () {
  if (Utilities.getMeasurementSystem() === window.CONSTANTS.MEASUREMENT_SYSTEM_METRIC) {
    return ' ' + $vue.$root.$t('header__search__distance__kilometers')
  }
  return ' ' + $vue.$root.$t('header__search__distance__miles')
}

function toMiles (value) {
  return (value / 1609.344).toFixed(1)
}

function toKilometers (value) {
  return (value / 1000).toFixed(1)
}

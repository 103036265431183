import appsettings from '@/appsettings'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { getPlatformInfo } from './utils'
// Install the VueApollo plugin
Vue.use(VueApollo)

// Set up the Apollo Client
const httpLink = new HttpLink({
  uri: appsettings.EVENT_RESERVATION_GRAPHQL_SERVICE,
  headers: {
    'X-wotc-client': `client:locator version:${appsettings.LOCATOR_VERSION} platform:${getPlatformInfo()}`
  }
})

const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  
})

// Export the VueApollo provider
export default new VueApollo({
  defaultClient: apolloClient
})

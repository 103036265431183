<template>
  <div
    v-if="showPrivacyMessage"
    class="privacy-policy sticky"
  >
    <div
      class="privacy-message"
      v-html="getPrivacyMessage"
    />
    <div
      class="close-me"
      @click="togglePrivacyBanner(false)"
    >
      <i
        class="fa fa-times"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PrivacyBanner',
  data () {
    return {
      showPrivacyMessage: false
    }
  },
  computed: {
    getPrivacyMessage () {
      return this.$t('footer__privacy_banner__message')
    }
  },
  mounted () {
    const dateString = this.$t('footer__privacy_banner__start_date')
    const availableDays = this.$t('footer__privacy_banner__days') || 0
    const startDate = moment(dateString, 'DD-MM-YYYY')
    const endDate = moment(dateString, 'DD-MM-YYYY').add(parseInt(availableDays), 'd')
    const today = moment()
    if (today <= endDate && startDate <= today) {
      this.togglePrivacyBanner(true)
    }
  },
  methods: {
    togglePrivacyBanner (flag) {
      this.showPrivacyMessage = flag
    }
  }
}
</script>

<style scoped lang="scss">
.privacy-policy {
  width: 100%;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 15px;
  z-index: 1000;
  color: map-get($theme-colors, "white-color");
  font-size: 0.875em;
  font-style: italic;
  text-align: center;
  border-top: 1px solid map-get($theme-colors, "border-dark");
  display: flex;
  justify-content: center;
}
.sticky {
  position: sticky;
  top: 0;
  left: 0;
}
.close-me{
  font-size: 15px;
  padding-left: 15px;
  cursor: pointer;
}
</style>

<template>
  <div id="app">
    <div v-if="$route.name === 'health'">
      <router-view />
    </div>
    <div v-else>
      <privacy-banner />
      <navbar />
      <div class="app-content-wrapper">
        <router-view />
      </div>
      <page-footer />
    </div>
  </div>
</template>

<script>
import Navbar from '@/features/common/components/Navbar.vue'
import PrivacyBanner from '@/features/common/components/PrivacyBanner.vue'
import PageFooter from '@/features/common/components/footer/PageFooter.vue'

export default {
  name: 'App',
  components: {
    'privacy-banner': PrivacyBanner,
    navbar: Navbar,
    'page-footer': PageFooter
  },
  data: function () {
    return {
      isPrerendered: window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered
    }
  },
  metaInfo: function () {
    const titleTemplate = chunk => `${chunk} | Wizards Store & Event Locator`
    return {
      titleTemplate,
      meta: [{
        property: 'og:type',
        content: 'website'
      }]
    }
  },
  beforeCreate: function () {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

.form-control:focus {
  border-color: #666666;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#app {
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: map-get($theme-colors, 'bold-gray');
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content-wrapper {
  flex: 1;
}

.primary-color {
  color: map-get($theme-colors, primary);
}

.display-none {
  display: none;
}

.outline-none {
  outline: none;
}

.dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.dropdown li:last-child {
  border-bottom: none;
}

.dropdown li a {
  padding: 10px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 1.25em;
}

.dropdown li a .fa {
  padding-right: 0.5em;
}

.bg-texture {
  background-image: url('@/assets/backgrounds/texture-tile.jpg');
}

.plain-link-white {
 color: #fff!important;
}

.plain-link-white:hover {
  text-decoration: none!important;
}

.plain-link {
 color: #000!important;
}

.plain-link:hover {
  text-decoration: none!important;
}
</style>

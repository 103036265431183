import platform from 'platform'

export const getPlatformInfo  = () => {
  // ex: Mac OS/chrome/112.0.0
  if(platform) {
    if(platform.os) {
      return `${platform.os.family}/${platform.name}/${platform.version}`
    } else {
      return `Unknown OS/${platform.name}/${platform.version}`
    }
  } else {
    return 'Platform Not Detected'
  }
}
export default {
  "it": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "Successiva",
    "pagination__previous": "Precedente",
    "sort_selector__date": "Data",
    "sort_selector__distance": "Distance",
    "event_details": "Dettagli evento",
    "event_details__add_to_calendar": "Aggiungi al calendario",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "Rimuovi",
    "filters__clear_all_filters": "Rimuovi tutti i filtri",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "Ricerca di eventi | Localizzatore di eventi e negozi Wizards",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "Commenti",
    "header__find_events": "Cerca eventi",
    "header__title": "Localizzatore di negozi ed eventi",
    "header__classic_link": "Locator Classic",
    "header__search__near": "Vicino a:",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "distanza",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "mi",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "Marchi",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "Informazioni",
    "footer__company__careers": "Opportunità di lavoro",
    "footer__company__heading": "Azienda",
    "footer__company__support": "Assistenza",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, una filiale di Hasbro, Inc. Tutti i diritti riservati.",
    "footer__find__articles": "Articoli",
    "footer__find__club_support": "Club Support Program",
    "footer__find__convention_support": "Convention Support Program",
    "footer__find__digital_books": "Libri digitali",
    "footer__find__formats": "Formati",
    "footer__find__forums": "Forum",
    "footer__find__heading": "Scopri",
    "footer__find__military_support": "Military Support Program",
    "footer__find__rules": "Regole",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "Social network",
    "footer__utils__code_of_conduct": "Codici di condotta",
    "footer__utils__cookies": "Cookie",
    "footer__utils__customer_support": "Servizio di assistenza",
    "footer__utils__fan_content_policy": "Linee guida sui contenuti amatoriali",
    "footer__utils__privacy_policy": "Informativa sulla privacy",
    "footer__utils__terms": "Termini di utilizzo",
    "footer__utils__donotsell": "Non vendere o condividere le mie informazioni personali",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>Vuoi giocare in una modalità che permetta a tutti di battersi ad armi pari e ti consenta di vedere nuove carte allo stesso tempo? Un formato Limited come il Booster Draft può fare al caso tuo. A differenza dei formati Constructed, dove arrivi già pronto con un mazzo attentamente costruito, i formati Limited ti consentono di costruire il mazzo con nuove carte come parte del divertimento.</p><p>All’inizio di un Booster Draft, ogni giocatore apre una busta e sceglie la carta che desidera. (Non far vedere agli altri giocatori quella che scegli!) Poi tutti passano il resto della busta al giocatore alla loro sinistra: ogni giocatore sceglie una carta dalla busta che ha appena ricevuto prima di passarla di nuovo. Questo procedimento continua fino a che non sono state draftate tutte le carte. Poi, ogni giocatore apre una seconda busta, ma questa volta la passa a destra. Dopo che sono state draftate tutte quelle carte, apri una terza busta e la passi ancora a sinistra.</p>",
    "event_content__description__dnd": "<p>Il gioco di ruolo Dungeons & Dragons si basa sulla narrazione ed è ambientato in mondi in cui spade e magia sono all’ordine del giorno. Condivide alcuni elementi con i giochi dell’infanzia in cui si creavano storie fantastiche: come quei giochi, D&D è incentrato sull’immaginazione. Si tratta di costruire nella mente un castello torreggiante sotto il cielo notturno in tempesta e decidere come reagirebbe un avventuriero in un mondo fantasy di fronte a quella scena.</p><p>A differenza dei giochi d’infanzia, tuttavia, D&D fornisce una struttura alle storie, un metodo per determinare le conseguenze delle azioni degli avventurieri. I giocatori tirano dadi per scoprire se i loro attacchi vanno a segno o mancano, oppure se i loro avventurieri possono scalare una scarpata, evitare un fulmine magico o portare a compimento altre imprese rischiose. Tutto è possibile, ma i dadi rendono alcuni esiti più probabili di altri.</p><p>In Dungeons & Dragons, ogni giocatore crea un avventuriero (anche detto personaggio) e si unisce a una squadra di altri compagni (interpretati dagli amici). Collaborando, il gruppo può esplorare un sinistro dungeon, una città in rovina, un castello infestato, un tempio sperduto nella giungla o una grotta inondata di lava nelle profondità di una montagna misteriosa. Gli avventurieri possono risolvere rompicapi, parlare con gli altri personaggi, affrontare mostri fantastici e scoprire favolosi oggetti magici e altri tesori.</p>",
    "event_content__description__magic": "Non importa se non hai mai provato un gioco di carte collezionabili o se ne hai il ripostiglio pieno: Magic: The Gathering ha qualcosa in serbo per te. Gioca online o con le carte fisiche per provare tutte le emozioni di un duello tra stregoni. Esplora 30 anni di storie, personaggi e arte. Conosci nuovi amici agli eventi di Magic nel tuo negozio di zona. Esistono molti modi per far parte della community di Magic: qualunque tu scelga, siamo felici di darti il benvenuto!",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "Formato:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft"
  }
}
import arlib from '@/features/common/lib/AnalyticsReporter.js'

// ------------------------------
// Helper functions that interface with Wizards AnalyticsReporter.js that
// provide customized functionality for the types of messages that SEL
// reports.
// ------------------------------

// The auxiliaryData object is sent along with every report on in addition to
// the action specific information.
// The auxiliaryData is only updated when a search is run.
const auxiliaryData = {
  query: {
    searchType: 'UNSET',
    location: 'UNSET',
    distance: 0,
    distanceUnits: 'UNSET',
    tags: [],
    storeIds: [],
    page: -1,
    order: 'UNSET',
    resultQuantity: 0
  },
  availableEventTypeFilters: [],
  availableFormatFilters: [],
  availableStores: []
}

// HELPERS

// Helper function that is called by each of the specific report functions.
// @param type <string> - the report type (Eg. 'searchView')
// @param data <object> - the report specific data to be sent
const reportTypedAction = (type, data) => {
  arlib.reportInteraction(type, Object.assign({}, auxiliaryData, data))
}

// API

// Initialize the reporting system.
// Calls init on the underlying AnalyticsReporter.
// Must be called before other report functions are called.
// @param config <object> - settings for initializing reporting and the initial report.
//
// The config object can contain the following bindings:
// siteURL -- the URL of the current site (default: '')
// querySize -- the number of messages to store before sending to the server (default: 4)
// serviceUrl -- the URL for the service that receives the analytics payload (default: general reporting)
const init = (config) => {
  arlib.init(config)
}

// Report function for the occurrence of a search.
// @param availableEventTypeFilters <list> - the event filters available at the time of the search
// @param availableFormatFilters <list> - the format filters available at the time of the search
// @param availableStores <list> - the organization IDs for the stores in the filter list
const searchView = (query, availableEventTypeFilters, availableFormatFilters, availableStores) => {
  auxiliaryData.query = query
  auxiliaryData.availableEventTypeFilters = availableEventTypeFilters
  auxiliaryData.availableFormatFilters = availableFormatFilters
  auxiliaryData.availableStores = availableStores
  reportTypedAction('searchView', {})
}

// Report function called with a specific event is viewed.
// @param eventId <string> - the ID of the event
// @param organizationId <string> - the ID of the owner of the event
const eventView = (eventId, organizationId) => {
  reportTypedAction('eventView', { eventId, organizationId })
}

// Report function called when a specific store is viewed.
// @param organizationId <string> - the ID of the store that is viewed
const storeView = (organizationId) => {
  reportTypedAction('storeView', { organizationId })
}

// Report function called when an organization URL is clicked.
// @param url <string> - the URL that was clicked, including any inline parameters
const storeURLClick = (url) => {
  reportTypedAction('storeUrlClick', { url })
}

// Report function called when the user requests a (ico) calendar item download.
// @param eventId <string> - the ID of the event associated with the datetime
// @param organizationId <string> - the ID for the organization that published the event
// @param EventTimestamp <string> - the ISO formatted datetime for the event
const calendarAdd = (eventId, organizationId, eventTimestamp) => {
  reportTypedAction('calendarAdd', { eventId, organizationId, eventTimestamp })
}

// EXPORTS

export default {
  init,
  searchView,
  eventView,
  storeView,
  storeURLClick,
  calendarAdd
}

import FacebookIcon from '@/assets/icons/btn_facebook.svg'
import TwitterIcon from '@/assets/icons/btn_twitter.svg'
import LinkedInIcon from '@/assets/icons/btn_linkedin.svg'
import MagicIcon from '@/assets/icons/magic_vector.svg'
import DnDIcon from '@/assets/icons/dnd.png'
import PlayingMtgIcon from '@/assets/playing_mtg.jpg'
import PlayingDnDIcon from '@/assets/playing_dnd.jpg'
import HomeBackgroundIcon from '@/assets/backgrounds/img_locator_new.svg'
import ArenaIcon from '@/assets/promos/mtg-arena.jpg'
import BackgroundIcon from '@/assets/backgrounds/new_imgstore-locator_bg.svg'
import BannerDnDIcon from '@/assets/backgrounds/bkgd_players_5e_sm.jpg'
import LilianaIcon from '@/assets/liliana-scaled.jpg'
import JaceIcon from '@/assets/jace-scaled.jpg'
import TamiyoIcon from '@/assets/tamiyo-scaled.jpg'
import GideonIcon from '@/assets/gideon-scaled.jpg'
import ChandraIcon from '@/assets/chandra-scaled.jpg'
import ShopIcon from '@/assets/icons/event/shop.svg'
import LocationIcon from '@/assets/icons/event/location.svg'
import RouteIcon from '@/assets/icons/event/route.svg'
import CalendarIcon from '@/assets/icons/event/calendar.svg'
import PhoneIcon from '@/assets/icons/event/phone.svg'
import ScheduleIcon from '@/assets/icons/event/schedule.svg'
import TagsIcon from '@/assets/icons/event/tags.svg'
import InfoIcon from '@/assets/icons/event/ic_info.svg'
import CalendarDateIcon from '@/assets/icons/event/calendar_date.svg'
import CalendarDaysIcon from '@/assets/icons/event/calendar_days.svg'
import FilterIcon from '@/assets/icons/event/filter_list.svg'

export default {
  getBrandImage,
  getBrandSharingImage,
  getBannerImages,
  getPromoImages,
  getDisplayTags,
  getFormatBlocks,
  gethomeBackground,
  getEventCardIcons,
  getSocialIconByName,
  searchHeaderBackground
}

const formatBlocksDict = {
  isBoosterDraft: 'booster_draft',
  isModern: 'modern',
  isStandard: 'standard',
  isDnD: 'dungeons_&_dragons',
  isMagic: 'magic:_the_gathering'
}

const brandImageDict = {
  default: MagicIcon,
  'magic:_the_gathering': MagicIcon,
  magic_premier_event: MagicIcon,
  'dungeons_&_dragons': DnDIcon
}

const brandSharingImageDict = {
  default: PlayingMtgIcon,
  'magic:_the_gathering': PlayingMtgIcon,
  'dungeons_&_dragons': PlayingDnDIcon
}

const promoImageDict = {
  default: {
    url: 'https://magic.wizards.com/en/mtgarena?utm_source=event-locator',
    xs: ArenaIcon
  }
}
const homeBackground = {
  default: {
    sm: HomeBackgroundIcon
  }
}

const bannerImageDict = {
  default: {
    isBrandOnlyTag: true,
    xs: BackgroundIcon,
    sm: BackgroundIcon
  },
  'dungeons_&_dragons': {
    isBrandOnlyTag: true,
    xs: BannerDnDIcon
  },
  booster_draft: {
    isBrandOnlyTag: false,
    xs: LilianaIcon
  },
  standard: {
    isBrandOnlyTag: false,
    xs: JaceIcon
  },
  commander: {
    isBrandOnlyTag: false,
    xs: TamiyoIcon
  },
  legacy: {
    isBrandOnlyTag: false,
    xs: GideonIcon
  },
  constructed: {
    isBrandOnlyTag: false,
    xs: ChandraIcon
  }
}

const socialIcons = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  linkedin: LinkedInIcon
}

const eventCardIcons = {
  shop: ShopIcon,
  location: LocationIcon,
  route: RouteIcon,
  calendar: CalendarIcon,
  phone: PhoneIcon,
  schedule: ScheduleIcon,
  tags: TagsIcon,
  info: InfoIcon,
  calendarDate: CalendarDateIcon,
  calendarDays: CalendarDaysIcon,
  filter: FilterIcon
}

const bgs = {
  searchHeader: BackgroundIcon
}

function searchHeaderBackground () {
  return bgs.searchHeader || ''
}

function getSocialIconByName (name) {
  return socialIcons[name]
}

function getDisplayTags (tags) {
  return tags.filter(tag => !brandImageDict[tag])
}

function getPromoImages () {
  return promoImageDict.default
}

function gethomeBackground () {
  return homeBackground.default
}

function getBannerImages (tags, brandOnlyTags) {
  let images = bannerImageDict.default
  if (!tags || typeof $vue === 'undefined') {
    return images
  }

  if (Array.isArray(tags)) {
    tags.forEach(tag => {
      if (bannerImageDict[tag]) {
        if (!brandOnlyTags) {
          images = bannerImageDict[tag]
        } else if (bannerImageDict[tag].isBrandOnlyTag) {
          images = bannerImageDict[tag]
        }
      }
    })
  } else {
    if (bannerImageDict[tags]) {
      images = bannerImageDict[tags]
    }
  }

  return images
}

function getBrandImage (tags) {
  let image = null
  tags.forEach(tag => {
    if (brandImageDict[tag]) {
      image = brandImageDict[tag]
    }
  })
  return image || brandImageDict.default
}

function getBrandSharingImage (tags) {
  let image = null

  tags.forEach(tag => {
    if (brandSharingImageDict[tag]) {
      image = brandSharingImageDict[tag]
    }
  })
  return image || brandSharingImageDict.default
}

function getFormatBlocks (tags) {
  const formatBlocks = {}
  for (const [key, value] of Object.entries(formatBlocksDict)) {
    formatBlocks[key] = tags.indexOf(value) > -1
  }
  return formatBlocks
}

function getEventCardIcons (name) {
  return eventCardIcons[name] || ''
}

<template>
  <div class="company-links">
    <div class="titled_list titled_list--find">
      <div class="titled_list__title">
        {{ $root.$t('footer__find__heading') }}
      </div>
      <ul class="titled_list__list">
        <li>
          <a href="https://magic.wizards.com/en/articles">{{ $root.$t('footer__find__articles') }}</a>
        </li>
        <li>
          <a href="https://magic.wizards.com/en/content/magic-club-support-program">{{ $root.$t('footer__find__club_support') }}</a>
        </li>
        <li>
          <a href="https://magic.wizards.com/en/content/convention-support-program">{{ $root.$t('footer__find__convention_support') }}</a>
        </li>
        <li>
          <a
            href="https://www.amazon.com/s/ref=sr_pg_1?rh=n%3A283155%2Cp_45%3A3%2Cp_46%3ADuring%2Cp_47%3A2018%2Cp_n_feature_browse-bin%3A618073011%2Cp_30%3Awizards%20of%20the%20coast&amp;sort=relevanceexprank&amp;unfiltered=1&amp;ie=UTF8&amp;qid=1527106656"
            target="_blank"
          >{{ $root.$t('footer__find__digital_books') }}</a>
        </li>
        <li>
          <a href="https://forums.mtgarena.com">{{ $root.$t('footer__find__forums') }}</a>
        </li>
        <li>
          <a href="https://magic.wizards.com/en/game-info/gameplay/rules-and-formats/formats">{{ $root.$t('footer__find__formats') }}</a>
        </li>
        <li>
          <a href="https://magic.wizards.com/en/game-info/gameplay/rules-and-formats/rules">{{ $root.$t('footer__find__rules') }}</a>
        </li>
        <li>
          <a href="https://magic.wizards.com/en/events/resources/magic-military-support">{{ $root.$t('footer__find__military_support') }}</a>
        </li>
      </ul>
    </div>

    <div class="titled_list titled_list--company">
      <div class="titled_list__title">
        {{ $root.$t('footer__company__heading') }}
      </div>
      <ul class="titled_list__list">
        <li>
          <a href="https://company.wizards.com/content/company">{{ $root.$t('footer__company__about') }}</a>
        </li>
        <li>
          <a href="https://company.wizards.com/content/jobs">{{ $root.$t('footer__company__careers') }}</a>
        </li>
        <li>
          <a href="https://support.wizards.com/">{{ $root.$t('footer__company__support') }}</a>
        </li>
        <li>
          <a href="https://wpn.wizards.com">{{ $root.$t('footer__company__wpn') }}</a>
        </li>
      </ul>
    </div>

    <div class="titled_list titled_list--brands">
      <div class="titled_list__title">
        {{ $root.$t('footer__brands__heading') }}
      </div>
      <ul class="titled_list__list">
        <li>
          <a href="https://avalonhill.wizards.com">{{ $root.$t('footer__brands__avalon_hill') }}</a>
        </li>
        <li>
          <a href="https://dnd.wizards.com">{{ $root.$t('footer__brands__dnd') }}</a>
        </li>
        <li>
          <a href="https://dm.takaratomy.co.jp">{{ $root.$t('footer__brands__duel_masters') }}</a>
        </li>
        <li>
          <a href="https://magic.wizards.com">{{ $root.$t('footer__brands__magic') }}</a>
        </li>
      </ul>
    </div>

    <div class="logos">
      <div class="logo logo--wotc">
        <a href="https://company.wizards.com"><img
          :src="require('@/assets/logo-wotc-2023.svg')"
          alt="Wizards of the coast"
        ></a>
      </div>
      <div class="logo logo--hasbro">
        <a href="https://www.hasbro.com/"><img
          :src="require('@/assets/logo-hasbro.svg')"
          alt="Hasbro"
        ></a>
      </div>
      <div class="logo logo--privacy">
        <a href="https://www.esrb.org/confirm/wizards-confirmation.aspx"><img
          :src="require('@/assets/logo-esrb.svg')"
          alt="Wizards confirmation"
        ></a>
      </div>
      <div
        v-show="showEsrb"
        class="logo logo--esrb"
      >
        <a href="https://www.esrb.org/ratings/ratings_guide.aspx"><img
          :src="require('@/assets/mtga-esrb.png')"
          alt="Ratings guide"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyLinks',
  computed: {
    showEsrb () {
      return this.$i18n.locale === 'en'
    }
  }
}
</script>

<style lang="scss">
  .company-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 280px;
    margin: 0 10%;
    padding-bottom: 20px;
  }
  .titled_list__title {
    @include titled_list__title;
  }
  .titled_list__list {
    list-style-type: none;
    padding-left: $footer-mobile-padding-horizontal;
    line-height: 1.2;
    li { padding-bottom: 7px; }
    a { color: #e8e8e8; }
  }
  .logos {
    grid-area: "logos";
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    justify-items: center;
    a { border: none; }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo--hasbro {
    justify-self: left;
  }
  .logo--esrb {
    margin-left: 8px;
    overflow: hidden;
  }
  @include media-breakpoint-down(sm) {
    .company-links {
      grid-template-columns: 45% 45%;
      grid-template-rows: auto;
      margin: 0;
    }
    .titled_list--find {
      grid-area: 1 / 1;
    }
    .titled_list--company {
      grid-area: 1 / 2;
    }
    .titled_list--brands {
      grid-area: 2 / 1;
      padding-bottom: 15px;
    }
    .logos {
      grid-area: 3 / 1 / 3 / 2;
      padding-left: 53px;
    }
  }
</style>

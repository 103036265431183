export default {
  "en": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "Next",
    "pagination__previous": "Previous",
    "sort_selector__date": "Date",
    "sort_selector__distance": "Distance",
    "event_details": "Event Details",
    "event_details__add_to_calendar": "Add to Calendar",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "Clear",
    "filters__clear_all_filters": "Clear All Filters",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "Events Search | Wizards Store & Event Locator",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "Feedback",
    "header__find_events": "Find",
    "header__title": "Store & Event Locator",
    "header__classic_link": "Locator Classic",
    "header__search__near": "Near",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "Distance",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "mi",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__dnd": "Dungeons & Dragons",
    "footer__brands__avalon_hill": "Avalon Hill",
    "footer__brands__duel_masters": "Duel Masters",
    "footer__brands__magic": "Magic",
    "footer__brands__heading": "Brands",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "About",
    "footer__company__careers": "Careers",
    "footer__company__heading": "Company",
    "footer__company__support": "Support",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, a subsidiary of Hasbro, Inc. All Rights Reserved.",
    "footer__find__articles": "Articles",
    "footer__find__club_support": "Club Support",
    "footer__find__convention_support": "Convention Support",
    "footer__find__digital_books": "Digital Books",
    "footer__find__formats": "Formats",
    "footer__find__forums": "Forums",
    "footer__find__heading": "Find",
    "footer__find__military_support": "Military Support",
    "footer__find__rules": "Rules",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "Social",
    "footer__utils__code_of_conduct": "Code of Conduct",
    "footer__utils__cookies": "Cookies",
    "footer__utils__customer_support": "Customer Support",
    "footer__utils__fan_content_policy": "Fan Content Policy",
    "footer__utils__privacy_policy": "Privacy Policy",
    "footer__utils__terms": "Terms",
    "footer__utils__donotsell": "Do Not Sell or Share My Personal Information",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>Want a way to play that offers a level playing field and lets you check out new cards at the same time? Then a limited format like booster draft may be for you. Unlike constructed formats, where you arrive with a carefully constructed deck ready to play, limited formats allow you to build a deck from new cards as part of the game.</p><p>At the start of booster draft, each player opens a booster pack and picks a single card. (Don’t show the other players what you pick!) Then everyone passes the rest of their pack to player on their left, each player then picks a card from the pack they just received before passing again. This process continues until all the cards in those packs have been drafted. Then each player opens a second pack, but this time, you pass the pack to your right. After all those cards are drafted, you do the same with the third pack, passing to the left again.</p>",
    "event_content__description__dnd": "<p>The Dungeons & Dragons roleplaying game is about storytelling in worlds of sword and sorcery. It shares elements with childhood games of make-believe. Like those games, D&D is driven by imagination. It’s about picturing the towering castle beneath the stormy night sky and imagining how a fantasy adventurer might react to the challenges that scene presents.</p><p>Unlike a game of make-believe, D&D gives structure to the stories, a way of determining the consequences of the adventurers’ action. Players roll dice to resolve whether their attacks hit or miss or whether their adventurers can scale a cliff, roll away from the strike of a magical lightning bolt, or pull off some other dangerous task. Anything is possible, but the dice make some outcomes more probable than others.</p><p>In the Dungeons & Dragons game, each player creates an adventurer (also called a character) and teams up with other adventurers (played by friends). Working together, the group might explore a dark dungeon, a ruined city, a haunted castle, a lost temple deep in a jungle, or a lava-filled cavern beneath a mysterious mountain. The adventurers can solve puzzles, talk with other characters, battle fantastic monsters, and discover fabulous magic items and other treasure.</p>",
    "event_content__description__magic": "Whether you've never played a trading card game before or your closet is filled with card games, Magic: The Gathering has something to offer you. Play online or in paper to experience the thrill of a wizards' duel. Explore 30 years' worth of stories, characters, and art. Make new friends at Magic events at your local game store. There are lots of ways to be a part of the Magic community, and we're so happy to have you!",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "Format:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft"
  }
}
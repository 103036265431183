export default {
  "es": {
    "glossary__status__errored": "We're sorry, we're not able to retrieve this information at the moment, please try back later",
    "glossary__status__invalid__location": "Not a valid search location, try for a different location",
    "glossary__status__loading": "Loading...",
    "glossary__status__no_events": "No events found for the location, distance and filters specified",
    "pagination__next": "Siguiente",
    "pagination__previous": "Anterior",
    "sort_selector__date": "Fecha",
    "sort_selector__distance": "Distance",
    "event_details": "Detalles del evento",
    "event_details__add_to_calendar": "Agregar al calendario",
    "event_details__meta__description__fallback": "Details of your event",
    "event_details__meta__title": "{eventName}: {eventDate} at {organizationName}",
    "filters": "Filters",
    "filters__clear": "Borrar",
    "filters__clear_all_filters": "Borrar todos los filtros",
    "filters__not_set": "(not set)",
    "filters__any_date": "(any date)",
    "filters__date_range": "{startDate} to {endDate}",
    "event_search__meta__description": "Search for events near {searchArea}",
    "event_search__meta__title": "Búsqueda de eventos | Localizador de tiendas y eventos de Wizards",
    "page_not_found__header": "Page Not Found",
    "page_not_found__content": "We couldn't locate the page you're looking for.<br>The link you followed may be outdated, or we may have moved it since you last bookmarked it.<br>Or it may be temporarily unavailable.",
    "page_not_found__return": "Search for an event",
    "search_presets__stores_near_you": "Stores Near You",
    "search_presets__friday_night_magic": "Friday Night Magic",
    "search_presets__dungeons_and_dragons": "Dungeons & Dragons",
    "search_presets__magic_premier_events": "Magic Premier Events",
    "header__feedback": "Comentarios",
    "header__find_events": "Buscar eventos",
    "header__title": "Localizador de tiendas y eventos",
    "header__classic_link": "Locator Classic",
    "header__search__near": "cerca:",
    "header__search__filter": "filter",
    "header__search__types__magic_events": "Magic Events",
    "header__search__types__magic_premier_events": "Magic Premier Events",
    "header__search__types__dnd": "D&D Events",
    "header__search__distance": "distancia",
    "header__search__types__stores": "Stores",
    "header__search__distance__miles": "mi",
    "header__search__distance__kilometers": "km",
    "header__search__distance__unlimited": "unlimited",
    "header__search": "Search",
    "header__search__types__magic_premier_events_mobile": "Premier Events",
    "header__search__types__magic_events_mobile": "Magic",
    "header__search__types__dnd_mobile": "D&D",
    "header__search__types__stores_mobile": "Stores",
    "header__search__types__magic_events_desktop": "Magic Events",
    "header__search__types__magic_premier_events_desktop": "Premier Events",
    "header__search__types__dnd_desktop": "D&D Events",
    "header__search__types__stores_desktop": "Stores",
    "header__banner": "We have updated our <a target=\"_blank\" href=\"https://company.wizards.com/tou\">terms of use</a> and <a target=\"_blank\" href=\"https://company.wizards.com/policy\">privacy policy</a>. Click the links to learn more.",
    "store_details": "Store Details",
    "tag_text__two_headed_giant": "Magic with a partner",
    "tag_text__booster_draft": "Magic with cards selected from a booster",
    "tag_text__commander": "100-card Magic deck fun",
    "tag_text__legacy": "Build your deck out of cards from every Magic set",
    "tag_text__modern": "Play with many Magic sets",
    "tag_text__multiplayer": "Play Magic against many opponents",
    "tag_text__sealed_deck": "Play Magic with boosters provided to you",
    "tag_text__standard": "Play with recent Magic sets",
    "tag_text__pauper": "Play with only common Magic cards",
    "footer__brands__heading": "Marcas",
    "footer__cities__heading": "Events in Other Cities",
    "footer__company__about": "Acerca de",
    "footer__company__careers": "Empleo",
    "footer__company__heading": "Empresa",
    "footer__company__support": "Ayuda",
    "footer__company__wpn": "Wizards Play Network",
    "footer__copyrights": "© 1993-2024 Wizards of the Coast LLC, una filial de Hasbro, Inc. Todos los derechos reservados.",
    "footer__find__articles": "Artículos",
    "footer__find__club_support": "Club Support",
    "footer__find__convention_support": "Convention Support",
    "footer__find__digital_books": "Libros digitales",
    "footer__find__formats": "Formatos",
    "footer__find__forums": "Foros",
    "footer__find__heading": "Descubrir",
    "footer__find__military_support": "Military Support",
    "footer__find__rules": "Reglas",
    "footer__socials__connect": "Connect with Wizards",
    "footer__socials__connect2": "on social media",
    "footer__socials__heading": "Social",
    "footer__utils__code_of_conduct": "Código de conducta",
    "footer__utils__cookies": "Cookies",
    "footer__utils__customer_support": "Atención al cliente",
    "footer__utils__fan_content_policy": "Política de contenido de fans",
    "footer__utils__privacy_policy": "Política de privacidad",
    "footer__utils__terms": "Términos",
    "footer__utils__donotsell": "No quiero que se venda ni comparta mi información personal.",
    "footer__privacy_banner__start_date": "01-07-2023",
    "footer__privacy_banner__days": "30",
    "footer__privacy_banner__message": "We have updated our <a href=\"https://company.wizards.com/en/legal/wizards-coasts-privacy-policy\">privacy policy</a>. Click the link to learn more.",
    "cookies__page__title": "What is a cookie?",
    "cookies__page__describe_cookies": "Cookies are small text files that are placed on your device by apps and websites that you visit. Cookies are widely used in order to make websites work, fulfil your preferences, and to provide information to the owners of websites. The table below explains the cookies we use and why.",
    "cookies__page__table_header__cookie_name": "Cookie Name",
    "cookies__page__table_header__domain": "Domain",
    "cookies__page__table_header__description": "Description",
    "cookies__description__gat": "Used to throttle request rate. if Google Analytics is deployed via Google Tag manager, this cookie will be named _dc_gtm_15020098",
    "cookies__description__gid": "Used to distinguish users.",
    "cookies__description__ga": "Used to distinguish users.",
    "cookies__description__cookie_agree": "This cookie is set as part of cookie notification on the Event Locator web site.",
    "cookies__description__aec": "These cookies prevent malicious sites from acting on behalf of a user without that user’s knowledge",
    "cookies__description__nid": "The “NID” cookie is used to play YouTube videos embedded on the website.",
    "cookies__description__enid": "This cookie is used by Google to remember your preferences and other information.",
    "cookies__description__doubleclick": "These cookies set by a third party (DoubleClick) and are used for serving targeted advertisements that are relevant to you across the web.",
    "cookies__banner__accept": "OK, I agree",
    "cookies__banner__decline": "No, thanks",
    "cookies__banner__copy": "We use necessary cookies to allow our site to function correctly and collect anonymous session data. Necessary cookies can be opted out through your browser settings. We also use optional cookies to personalize content and ads, provide social medial features and analyze web traffic. By clicking “OK, I agree,” you consent to optional cookies.  (<a href=\"{link}\">Learn more about cookies.</a>)",
    "event_content__description__booster_draft": "<p>¿Quieres un modo de juego que ofrezca un cierto nivel y que te permita conocer cartas nuevas al mismo tiempo? En ese caso, tal vez te convenga un formato limitado como Booster Draft. A diferencia de los formatos Construidos, que requieren ir con un mazo cuidadosamente construido y listo para jugar, los formatos limitados te permiten construir un mazo con cartas nuevas como parte del juego.</p><p>Para empezar, cada jugador abre un sobre y elige una única carta (¡sin enseñar a los demás la que ha escogido!). A continuación, pasa el resto de las cartas al jugador a su izquierda y cada jugador elige una carta del sobre que acaba de recibir, antes de pasar al siguiente las cartas restantes de nuevo. Este proceso continúa hasta que todas las cartas de los sobres hayan sido elegidas. Después se procede a la apertura de un segundo sobre, pero esta vez se pasa a la derecha. Una vez agotadas todas esas cartas, se repite el proceso con un tercer sobre, solo que pasándolo hacia la izquierda de nuevo.</p>",
    "event_content__description__dnd": "<p>El juego de rol Dungeons & Dragons (D&D) consiste en contar historias en mundos de espada y brujería. Comparte elementos con los juegos de fantasía de los niños y, al igual que en ellos, el motor de D&D es la imaginación: describir un castillo que se alza en el tormentoso cielo nocturno e imaginar cómo un aventurero de fantasía podría reaccionar a los desafíos que la escena le plantea.</p><p>A diferencia de los juegos de fantasía de los niños, D&D dota de estructura a las historias y proporciona un método para determinar las consecuencias de las acciones de los aventureros. Los jugadores tiran dados para averiguar si los ataques de sus personajes tienen éxito o fracasan, o si los aventureros consiguen escalar un precipicio, esquivar el impacto de un relámpago mágico o llevar a cabo cualquier otra tarea peligrosa. Todo es posible, pero los dados hacen que ciertas cosas sean más probables que otras.</p><p>En Dungeons & Dragons, cada jugador crea un aventurero (también llamado “personaje”) y se alía con otros aventureros (controlados por sus amigos). Trabajarán en grupo y así podrán explorar una mazmorra tenebrosa, una ciudad en ruinas, un castillo encantado, un templo perdido en lo más profundo de la selva o una caverna llena de lava situada bajo una misteriosa montaña. Los aventureros pueden resolver puzles, hablar con otros personajes, combatir contra monstruos fantásticos y descubrir fabulosos objetos mágicos y otros tesoros.</p>",
    "event_content__description__magic": "Tanto si nunca has jugado a un juego de cartas intercambiables como si tienes el armario repleto de estos juegos, Magic: The Gathering tiene algo para ofrecerte. Juega en línea o con cartas físicas y vive la emoción de un duelo entre hechiceros. Explora 30 años de historias, personajes e ilustraciones. Haz nuevos amigos en los eventos de Magic de tu tienda de juegos local. Hay muchas formas de formar parte de la comunidad de Magic, ¡y nos alegramos de tenerte en ella!",
    "event_content__description__modern": "<p>Modern is a constructed format and therefore adheres to the following constructed rules:</p><p>Minimum of sixty cards No maximum deck size, as long as you can shuffle your deck unassisted Up to fifteen cards in your sideboard, if used With the exception of basic lands (Plains, Island, Swamp, Mountain, and Forest), your combined deck and sideboard may not contain more than four of any individual card, counted by its English card title equivalent.</p><p>Note: The five snow-covered lands from the Ice Age block—Snow-Covered Plains, Snow-Covered Island, Snow-Covered Swamp, Snow-Covered Mountain, and Snow-Covered Forest—are also basic lands. Snow-covered lands are only permitted in formats that allow expansions from the Ice Age block.</p>",
    "event_content__description__standard": "Standard is a dynamic format where you build decks and play using cards in your collection from recently released Magic sets. Evolving gameplay and fresh strategies make it one of the most fun and popular ways to play Magic.",
    "event_content__format": "Formato:",
    "event_content__format__modern": "Modern",
    "event_content__format__standard": "Standard",
    "event_content__format__booster_draft": "Booster Draft"
  }
}
<template>
  <div class="copyright">
    {{ $root.$t('footer__copyrights') }}
  </div>
</template>

<script>
export default {
  name: 'Copyright'
}
</script>

<style lang="scss">
  .copyright {
    text-align: center;
    padding: 6px $footer-mobile-padding-horizontal;
    color: $footer-copyright-fg-color;
    font-size: 14px;
  }
</style>

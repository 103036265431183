const Banner = () => import('./Banner')
const ModelStatusMessages = () => import('./ModelStatusMessages')
const PageHeaderSeparator = () => import('./PageHeaderSeparator')
const GoogleMaps = () => import('./GoogleMaps')

export default { init }

function init (Vue) {
  Vue.component('Banner', Banner)
  Vue.component('ModelStatusMessages', ModelStatusMessages)
  Vue.component('PageHeaderSeparator', PageHeaderSeparator)
  Vue.component('GoogleMaps', GoogleMaps)
}
